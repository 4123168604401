import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import cameraImage from '../images/switch_camera.png'

const buttonStyle = {
  backgroundColor: '#000100',
  opacity: 0.6,
    color: 'white', // Added button text color
    fontSize: '12px', // Added button font size
    fontWeight: 'bold', // Added button font weight
    outline: 'none', // Added button outline
    transition: 'all 0.3s ease', // Added button transition
    borderRadius: '10%',
    border: 'none',
    marginBottom: '10px',
    height: '25px',
}

const SwitchCameraButton = ({ cameras, handleCameraSwitch, selectedCameraLabel, setSelectedCameraLabel, onClickOpen }) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = async () => {
    if (onClickOpen) await onClickOpen();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button onClick={handleClickOpen} style={{ width: '25px', padding: '0', border: 'none', background: 'none' }}>
        <img style={{ width: '80%', height: '80%', objectFit: 'cover' }} src={cameraImage} alt="Open Dialog" />
      </Button>
      <Dialog open={open} onClose={handleClose} PaperProps={{ style: { backgroundColor: 'transparent', boxShadow: 'none', position: 'relative', top: '38%', left: '0%', transform: 'translate(-50%, -50%)',  } }} 
      slotProps={{ backdrop: { style: { backgroundColor: 'rgba(0, 0, 0, 0.2)' } } }} >
        <DialogContent style={{ display: 'flex', flexDirection: 'column' }}>
            {cameras.length > 0 && cameras.map((camera) => ( 
              <button
                style={camera.label === selectedCameraLabel ? { ...buttonStyle, color: 'rgba(249, 222, 145, 1)' } : buttonStyle}
                key={camera.deviceId}
                onClick={() => {
                  handleCameraSwitch(camera.deviceId);
                  if (setSelectedCameraLabel)  setSelectedCameraLabel(camera.label);
                  handleClose();
                }}
              >
                {camera.label || camera.deviceId} 
              </button>
            ))}
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default SwitchCameraButton;
