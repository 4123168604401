import React from 'react';
import { isIOSDevice } from '../utils';

const OverlayScanner = ({ centerWidth, centerHeight, color }) => {
    return (
        <div style={{ '--center-width': `${centerWidth}px`, '--center-height': `${centerHeight}px`, position: 'absolute', top: 0, left: 0, width: '100%', height: isIOSDevice ? '100%' : '90%', pointerEvents: 'none' }}>
            <div style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                height: `calc(50% - var(--center-height) / 2)`,
                backgroundColor: color,
            }}></div>
            <div style={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                right: 0,
                height: `calc(50% - var(--center-height) / 2)`,
                backgroundColor: color,
            }}></div>
            <div style={{
                position: 'absolute',
                top: `calc(50% - var(--center-height) / 2)`,
                left: 0,
                bottom: `calc(50% - var(--center-height) / 2)`,
                width: `calc(50% - var(--center-width) / 2)`,
                backgroundColor: color,
            }}></div>
            <div style={{
                position: 'absolute',
                top: `calc(50% - var(--center-height) / 2)`,
                right: 0,
                bottom: `calc(50% - var(--center-height) / 2)`,
                width: `calc(50% - var(--center-width) / 2)`,
                backgroundColor: color,
            }}></div>
            {/* White Border */}
            <div style={{
                position: 'absolute',
                top: `calc(50% - var(--center-height) / 2)`,
                left: `calc(50% - var(--center-width) / 2)`,
                width: `var(--center-width)`,
                height: `var(--center-height)`,
                border: '2px solid white',
                boxSizing: 'border-box', // Ensure border is included in the dimensions
            }}></div>
            {/* Scanning Line */}
            <div style={{
                position: 'absolute',
                top: `calc(50% - var(--center-height) / 2 + 2px)`, // Start at the top of the white border
                left: `calc(50% - var(--center-width) / 2 + 2px)`, 
                width: `calc(var(--center-width) - 4px)`,
                height: '1px',
                backgroundColor: 'rgba(255, 0, 0, 0.5)',
                animation: 'scan 4s infinite'
            }}></div>
            <div style={{
                position: 'absolute',
                top: `calc(50% + var(--center-height) / 2 + 10px)`, // Adjusted to position below the white border
                left: '50%',
                transform: 'translateX(-50%)', // Center the text horizontally
                color: 'white',
                fontSize: '12px', // Adjust font size as needed
                textAlign: 'center',
            }}>
                Mover el recuadro sobre el código de barra
            </div>
            <style>{`
                @keyframes scan {
                    0% { top: calc(50% - var(--center-height) / 2 + 2px); }
                    50% { top: calc(50% + var(--center-height) / 2 - 2px); } // Stop just before the bottom of the white border
                    100% { top: calc(50% - var(--center-height) / 2 + 2px); }
                }
            `}</style>
        </div>
    );
}

export default OverlayScanner;
