import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { isNullOrUndef } from 'chart.js/helpers';
import { BarcodeScanner } from 'react-barcode-scanner'
import "react-barcode-scanner/polyfill"

import OverlayScanner from '../components/OverlayScanner';
import TextSearchButton from '../components/TextSearchButton';
import BarcodeResult from '../components/BarcodeResult';
import SwitchCameraButton from '../components/SwitchCameraButton';
import './BarcodeScannerView.css';

// const filterCameras = (cameras) => {
//     const filteredCameras = cameras.filter(camera => {
//         const cameraCapabilities = camera.getCapabilities();
//         console.log('Camera', camera.label, 'Info', cameraCapabilities);
//         return cameraCapabilities.facingMode && cameraCapabilities.facingMode[0] !== "user";

//     })
//     return filteredCameras;
// }


const BarcodeScannerView = () => {
    const { tenant, substore } = useParams();

    const [result, setResult] = useState(null); // result of the last scan, as returned by Quagga.onDetected()
    
    const [cameras, setCameras] = useState([]); // array of available cameras, as returned by Quagga.CameraAccess.enumerateVideoDevices()
    const [cameraId, setCameraId] = useState(null); // id of the active camera device
    const [selectedCameraLabel, setSelectedCameraLabel] = useState(null); // id of the active camera device
    const [isMounting, setIsMounting] = useState(true); //camera selection mounting

    let navigate = useNavigate();

    const redirectToStock = (result) => {
        console.log(result);
        if (tenant == null) return navigate('/'+result);
        const urlToNav = isNullOrUndef(substore) ? '/'+tenant+'/'+result : '/'+tenant+'/'+substore+'/'+result;
        return  navigate(urlToNav);
    }

    const getEnvironmentCameras = async () => {
        try {
            const devices = await navigator.mediaDevices.enumerateDevices();
            
            const videoDevices = devices.filter(device => device.kind === 'videoinput');
            const filteredByEnvironment = videoDevices;
            // const filteredByEnvironment = filterCameras(videoDevices);
            console.log('devices', filteredByEnvironment);
            setCameras(filteredByEnvironment);
        } catch (error) {
            console.error('Error accessing cameras:', error);
        }
    };

    const getCameraByLabel = (cameras, selectedCameraLabel) => {
        return cameras.filter(camera => camera.label === selectedCameraLabel);
    }

    /// Load selectedCameraLabel from local storage on component mount
    useEffect(() => {
        if (cameras.length !== 0) {
            const savedCameraSelection = localStorage.getItem('selectedCameraLabel');
            if (savedCameraSelection !== null) {
                setSelectedCameraLabel(savedCameraSelection);
                console.log("cameras HOLA", cameras);
                console.log("cameras HOLA", savedCameraSelection);
                const savedCamera = getCameraByLabel(cameras, savedCameraSelection)[0];
                if (savedCamera == null) {
                    setCameraId(null);
                    localStorage.setItem('selectedCameraLabel', null);
                } else {
                    setCameraId(savedCamera.deviceId);
                }
                
            }
            setIsMounting(false); // Update mounting flag after initial render
        }
        
    }, [cameras]);

    // Save selectedCameraLabel to local storage whenever it changes
    useEffect(() => {
        if (!isMounting && selectedCameraLabel) {
            localStorage.setItem('selectedCameraLabel', selectedCameraLabel);
        }
    }, [selectedCameraLabel, isMounting]);

    const getCapturedValueHandler = (value) => {
        const barcodeValue = value?.rawValue;
        if (barcodeValue) {
            if (barcodeValue.startsWith(']C1')) {
                const modifiedValue = barcodeValue.slice(3); // Remove ']C1' from the beginning
                setResult(modifiedValue);
            } else {
                setResult(barcodeValue);
            }
        }
    };
    console.log('deviceId', cameraId);
    return (
        <div style={{ width: '100%', height: '90vh' }}>
            {/* {cameraError ? 
                <div style={{ textAlign: 'center'}}>
                    {cameras.length === 0 && 
                        <>
                            <p style={{ textAlign: 'center'}}>El escaner requiere permisos de acceso a tu camara.</p>
                            <div>
                                <p>Para habilitar los permisos de la cámara, sigue estos pasos:</p>
                                <ol style={{ color: 'white', margin: '30px' }}>
                                    <li>Haz clic en el icono del candado en la barra de direcciones.</li>
                                    <li>Selecciona "Configuración del sitio".</li>
                                    <li>Busca "Cámara" y configúralo en "Permitir".</li>
                                    <li>Recarga la página.</li>
                                </ol>
                            </div>
                        </>
                    }
                </div> : 
                <>
                    <OverlayScanner centerHeight={160} centerWidth={340} color='rgba(0, 0, 0, 0.7)'/>
                    <div style={{ position: 'absolute', top: '5%', left: '3%', zIndex: 2}}> 
                        <TextSearchButton buttonStyle={{ width: '70px' }} />
                    </div>
                    <BarcodeScanner
                        options={{ 
                            delay: 1000, //Probar
                            formats: ['code_128', 'code_93'],
                            

                        }}
                        trackConstraints={cameraId ? { deviceId: { exact: cameraId } } : {} }
                        onCapture={getCapturedValueHandler}
                    />
                    <div style={{ position: 'absolute', bottom: '5%', left: '3%', zIndex: 2}}> 
                        <SwitchCameraButton cameras={cameras} handleCameraSwitch={(cameraId) => setCameraId(cameraId)} selectedCameraLabel={selectedCameraLabel} setSelectedCameraLabel={(cameraLabel) => setSelectedCameraLabel(cameraLabel)} />
                    </div>
                    {result && <div style={{ position: 'absolute',
                                top: '75%', width: window.innerWidth, height: '50px' }}>
                        <BarcodeResult result={result} redirectToStock={() => redirectToStock(result)}/>
                    </div>}
                </>
            } */}
            <>
                    <OverlayScanner centerHeight={160} centerWidth={340} color='rgba(0, 0, 0, 0.7)'/>
                    <div style={{ position: 'absolute', top: '5%', left: '3%', zIndex: 2}}> 
                        <TextSearchButton buttonStyle={{ width: '70px' }} />
                    </div>
                    <BarcodeScanner
                        options={{ 
                            delay: 1000, //Probar
                            formats: ['code_128', 'code_93'],
                            

                        }}
                        trackConstraints={cameraId ? { deviceId: { exact: cameraId } } : {} }
                        onCapture={getCapturedValueHandler}
                    />
                    <div style={{ position: 'absolute', bottom: '5%', left: '3%', zIndex: 2}}> 
                        <SwitchCameraButton cameras={cameras} handleCameraSwitch={(cameraId) => setCameraId(cameraId)} selectedCameraLabel={selectedCameraLabel} setSelectedCameraLabel={(cameraLabel) => setSelectedCameraLabel(cameraLabel)} onClickOpen={() => getEnvironmentCameras()} />
                    </div>
                    {result && <div style={{ position: 'absolute',
                                top: '75%', width: window.innerWidth, height: '50px' }}>
                        <BarcodeResult result={result} redirectToStock={() => redirectToStock(result)}/>
                    </div>}
                </>
        </div>
    );
};

export default BarcodeScannerView;
