import { initializeApp } from "firebase/app";
import 'firebase/firestore'; // For Cloud Firestore
import { getFirestore, collection } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBV9b_h9cFuyTXzWDslKzYkaO0Hw3ctq14",
  authDomain: "losmuchachos-stock.firebaseapp.com",
  projectId: "losmuchachos-stock",
  storageBucket: "losmuchachos-stock.appspot.com",
  messagingSenderId: "126046936074",
  appId: "1:126046936074:web:ea26301e9e7917a70f53f3"
};

const firebase = initializeApp(firebaseConfig);

// Access the Firestore instance
const firebaseDB = getFirestore(firebase);

const getTenantCollection = (tenant) => {
  console.log(tenant);
  if (tenant == null) return collection(firebaseDB, "geolocations");
  return collection(firebaseDB, tenant)
}

export { getTenantCollection, firebaseDB };