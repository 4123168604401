import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import icon from '../images/scanButton.png'
import { isNullOrUndef } from 'chart.js/helpers';

const ScanButton = ({ tenant, substore }) => {
    let navigate = useNavigate();

    const handleClickOpen = () => {
        console.log("scan");
        const urlToNav = isNullOrUndef(substore) ? '/'+tenant+'/scanner' : '/'+tenant+'/'+substore+'/scanner';
        navigate(urlToNav);
    };

    return (
    <div>
        <Button onClick={handleClickOpen} style={{ width: '80px', padding: '0', border: 'none', background: 'none'}}>
            <img style={{ width: '100%', height: '100%', objectFit: 'cover' }} src={icon} alt="Scan" />
        </Button>
    </div>
    );
}

export default ScanButton;
