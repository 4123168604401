import { BrowserRouter, Routes, Route } from 'react-router-dom';
import App from './App';
import Index from './pages/Index';
import MapUsage from './components/MapUsage';
import ChartUsage from './components/ChartUsage';
import BarcodeScannerViewConditional from './pages/BarcodeScannerViewConditional';

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        {/* <Route path="/:tenant/:id_item" element={withTenant(App)} />
        <Route path="/:tenant/scanner" element={withTenant(BarcodeScannerView)} />
        <Route path="/:tenant/usage" element={withTenant(ChartUsage)} />
        <Route path="/:tenant/map" element={withTenant(MapUsage)} /> */}
        <Route path="/:tenant/:substore/index" element={<Index />} />
        <Route path="/:tenant/:substore/:id_item" element={<App />} />
        <Route path="/:tenant/:substore/scanner" element={<BarcodeScannerViewConditional />} />
        
        <Route path="/:tenant/index" element={<Index />} />
        <Route path="/:tenant/:id_item" element={<App />} />
        <Route path="/:tenant/scanner" element={<BarcodeScannerViewConditional />} />
        <Route path="/:tenant/usage" element={<ChartUsage />} />
        <Route path="/:tenant/map" element={<MapUsage />} />
        
        {/* Define routes for the default tenant */}
        <Route path="/:id_item" element={<App />} />
        <Route path="/scanner" element={<BarcodeScannerViewConditional />} />
        <Route path="/usage" element={<ChartUsage />} />
        <Route path="/map" element={<MapUsage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
